<template>
  <div class="error-box">
    <h2 class="title">抱歉，页面未找到</h2>
    <el-button type="primary" @click="go()">返回首页</el-button>
  </div>
</template>

<script>
export default {
  name: "error",
  methods: {
    go() {
      this.$router.replace("/");
    }
  }
};
</script>

<style scoped lang="scss">
.error-box {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 30px;
    margin: 20px 0;
  }
}
</style>
